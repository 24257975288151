import { startTransition, useEffect, useState } from 'react';
import { Modal, Card, Image, Tabs, Form, Input, Button, Radio, Message } from '@arco-design/web-react';
import { IconUser, IconEmail, IconPhone, IconCalendar, IconUserGroup, IconBranch } from '@arco-design/web-react/icon';
import { useNavigate } from 'react-router-dom';
import { GET, PUT } from "@/utils/apis"
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
interface propInter {
  visible: boolean,
  setVisible: any,
}
const PersonalCenter = (props: propInter) => {
  const { visible, setVisible } = props;
  const [userInfor, setUserInfor] = useState<any>({})
  const [basicForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const navigate=useNavigate()
  const initdata = async () => {
    try {
      const res: any = await GET('/system/user/profile')
      if (res.code === 200) {
        setUserInfor(res.data)

      }
    } catch (error) {

    }
  }
  const handleSubmitBasic = async (v: any) => {
    try {
      const res: any = await PUT('/system/user/profile', {
        ...userInfor.user,
        ...v
      })
      if (res.code === 200) {
        initdata()
        Message.success('修改成功')
      } else {
        Message.warning('修改失败')
      }
    } catch (error: any) {
      Message.warning(error)
    }

  }

  const handlePassword=async(v:any)=>{
    try {
      const res: any = await PUT('/system/user/profile/updatePwd', {
        ...v
      },{
        isEncrypt: true
      })
      if (res.code === 200) {
        Message.success('修改成功')
        startTransition(()=>{
          navigate('/login')
        })
        setTimeout(() => {
          Message.success('请用新密码重新登录')
        }, 2000);
        localStorage.removeItem('Admin-Token')
      } else {
        Message.warning('修改失败')
      }
    } catch (error: any) {
      Message.warning(error)
    }
  }
  const validateConfirmPassword = ( value: any, callback: any) => {
    const password = passwordForm.getFieldValue("newPassword"); // 获取新密码
    if (!value) {
      callback("请确认密码");
    } else if (value !== password) {
      callback("两次输入的密码不一致");
    } else {
      callback(); // 通过校验
    }
  };
  useEffect(() => {
    initdata()
  }, [])
  useEffect(() => {
    basicForm.setFieldsValue(userInfor.user);
  }, [userInfor])
  return (
    <div className='personal-center'>
      <Modal
        title='个人中心'
        style={{ width: '80vw', height: '80vh' }}
        visible={visible}
        footer={null}
        autoFocus={false}
        focusLock={true}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <div className='grid grid-cols-[360px_1fr] w-full gap-4'>
          <Card

            title='个人信息'
            hoverable
          >
            <div className='flex flex-col items-center w-full'>
              <Image
                width={100}
                src={userInfor?.user?.avatar || '//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp'}
                alt='lamp'
              ></Image>
              <div className='w-full mt-2'>
                <div className='border-b flex justify-between items-center h-[40px]'><span><IconUser />用户名称</span><span>{userInfor?.user?.userName}</span></div>
                <div className='border-b flex justify-between items-center h-[40px]'><span><IconPhone />手机号码</span><span>{userInfor?.user?.phonenumber}</span></div>
                <div className='border-b flex justify-between items-center h-[40px]'><span><IconEmail />用户邮箱</span><span>{userInfor?.user?.email}</span></div>
                <div className='border-b flex justify-between items-center h-[40px]'><span><IconBranch />所属部门</span><span>{userInfor?.user?.dept?.deptName}</span></div>
                <div className='border-b flex justify-between items-center h-[40px]'><span><IconUserGroup />所属角色</span><span>{userInfor?.user?.remark}</span></div>
                <div className='border-b flex justify-between items-center h-[40px]'><span><IconCalendar />创建日期</span><span>{userInfor?.user?.createTime}</span></div>
              </div>
            </div>
          </Card>
          <Card

            className='basic-infor'
            title='基本资料'
            hoverable
          >
            <Tabs defaultActiveTab='1' className='p-0'>
              <TabPane key='1' title='基本资料'>
                <Form
                  form={basicForm}
                  layout="horizontal"
                  labelAlign='right'
                  initialValues={{ sex: '1' }}
                  autoComplete='off'
                  labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}
                  onSubmit={(v) => {
                    handleSubmitBasic(v)
                  }}
                >
                  <FormItem label='用户名称' field='userName' rules={[{ required: true }]}>
                    <Input placeholder='请输入你的昵称' disabled />
                  </FormItem>
                  <FormItem label='手机号码' field='phonenumber' rules={[{ required: true }, {
                    match: /^1[3-9]\d{9}$/,
                    message: "请输入正确的手机号"
                  }]}>
                    <Input placeholder='请输入你的电话号码' />
                  </FormItem>
                  <FormItem label='邮箱' field='email' rules={[{ required: true }, { type: "email", message: "请输入正确的邮箱地址" }]}>
                    <Input placeholder='请输入你的email' />
                  </FormItem>
                  <FormItem label='性别' field='sex'>
                    <RadioGroup defaultValue='1' style={{ marginBottom: 20 }}>
                      <Radio value='1'>男</Radio>
                      <Radio value='0'>女</Radio>
                    </RadioGroup>
                  </FormItem>

                  <FormItem wrapperCol={{ offset: 4 }}>
                    <Button type='primary' htmlType='submit' style={{ marginRight: 24 }}>
                      保存
                    </Button>
                  </FormItem>
                </Form>
              </TabPane>
              <TabPane key='2' title='修改密码'>
                <Form
                  form={passwordForm}
                  style={{ width: '100%' }}
                  initialValues={{ name: 'admin' }}
                  autoComplete='off'
                  labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}
                  onSubmit={(v) => {
                    handlePassword(v)
                  }}
                >
                  <FormItem label='旧密码' field='oldPassword' rules={[{ required: true, message: "旧密码不能为空", }]}>
                    <Input.Password placeholder='请输入旧密码' />
                  </FormItem>
                  <FormItem label='新密码' field='newPassword' rules={[{ required: true, message: "新密码不能为空", }, {
                    min: 6,
                    max: 20,
                    message: "长度在 6 到 20 个字符",
                  }]}>
                    <Input.Password placeholder='请输入新密码' />
                  </FormItem>
                  <FormItem label='确认密码' field='comfirmPassword' rules={[{ required: true, message: "确认密码不能为空" }, { validator: validateConfirmPassword, validateTrigger: "onBlur" },]}>
                    <Input.Password placeholder='请再次输入新密码' />
                  </FormItem>
                  <FormItem wrapperCol={{ offset: 4 }}>
                    <Button type='primary' htmlType='submit' style={{ marginRight: 24 }}>
                      保存
                    </Button>
                  </FormItem>
                </Form>
              </TabPane>

            </Tabs>
          </Card>
        </div>
      </Modal>
    </div>
  )
}
export default PersonalCenter