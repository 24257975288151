import { lazy } from "react";
import LayoutCom from '@/layouts/index'

interface IRouter {
    name?: string;
    index?: boolean;
    path: string;
    component: any;
    children?: Array<IRouter>
    meta?:{ title: string }
}

const router: Array<IRouter> = [
    {
        path: "/",
        component: LayoutCom,
        children: [
          {
            path: '/AIBoard/dashboard',
            index: true,
            meta:{title:'数据分析'},
            component: lazy(() => import("@/pages/DashBoard/index"))
          },
          {
            path: '/AIBoard/myReport',
            meta:{title:'我的报告'},
            component: lazy(() => import("@/pages/DashBoard/MyReport"))
          },
          {
            path: '/proxyBoard/dashboard',
            meta:{title:'运营数据分析'},
            component: lazy(() => import("@/pages/OperateDashboard/OperateDataAnnelyse"))
          },
          {
            path: '/proxyBoard/breakdownManage',
            meta:{title:'Breakdown管理'},
            component: lazy(() => import("@/pages/OperateDashboard/BreakDownManage"))
          },
      ]
    },
    {
        path: "login",
        component: lazy(() => import("@/pages/Login"))
    },
    {
        path: "social-callback",
        component: lazy(() => import("@/pages/SocialCallback"))
    },
    {
        path: '*',
        component: lazy(() => import("@/pages/NotFound"))
    }
]

export default router;