import { create } from 'zustand'
import { persist } from "zustand/middleware";
import {
  ITreeType,
  sourceItem
} from '@/types/dashbroad';
interface State {
  projectId: string,
  algorithmId: string,
  algorithmMaps:Map<any, any>,
  projectAlgTree:ITreeType[],
  funnel5AData:sourceItem[],
  currenBreakdown:string
  breakdownName:string
}

interface Action {
  setProjectId: (id:string) => void;
  setAlgorithmId: (id:string) => void;
  getProAndAlg: () => string;
  setAlgorithmMap:( algorithmMap:Map<any, any>)=> void;
  setProjectAlgTree:(tree:ITreeType[])=>void
  setFunnel5AData:(data:sourceItem[])=>void
  setCurrentBreakdown:(data:string)=>void
  setBreakdownName:(data:string)=>void
}

const useGlobalStore = create<State & Action>()(
persist(
  (set) => ({
    projectId: '',
    algorithmId:'',
    algorithmMaps:new Map(),
    projectAlgTree:[],
    funnel5AData:[],
    currenBreakdown:'',
    breakdownName:'',
    setProjectId: (id) => set({ projectId: id }),
    setAlgorithmId: (id) => set({ algorithmId: id }),
    getProAndAlg: () => {
      const state:State & Action = useGlobalStore.getState()
      return `${state.projectId}_${state.algorithmId}`
    },
    setAlgorithmMap:(map)=>set({algorithmMaps:map}),
    setProjectAlgTree:(tree)=>set({projectAlgTree:tree}),
    setFunnel5AData:(data)=>set({funnel5AData:data}),
    setCurrentBreakdown:(data)=>set({currenBreakdown:data}),
    setBreakdownName:(data)=>set({breakdownName:data}),
  }),{
    name: "breakdown-storage", // 存储在 localStorage 的 key
    partialize: (state) => ({ currenBreakdown: state.currenBreakdown ,breakdownName:state.breakdownName}), // 只持久化 token
  }
)
)

export default useGlobalStore





