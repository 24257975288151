import React, { useState } from "react";
import DemoImg from "@/assets/images/R-C.png";
import { IUserInfo } from "@/types/user"
import { Dropdown, Menu, Message, Cascader } from "@arco-design/web-react";
import { POST } from "@/utils/apis";
import { removeToken } from "@/utils/apis/auth"
import PersonalCenter from "./PersonalCenter";
import '../index.scss'

interface Iprops {
  userInfo: IUserInfo | undefined
  projectTree: any;
  activeNodes: any;
  setActiveNodes: any
}
const Index = (props: Iprops): React.ReactElement => {
  const { projectTree, activeNodes, setActiveNodes } = props
  const [visible, setVisible] = useState(false)
  const loginOut = async () => {
    try {
      await POST('/auth/logout');
      Message.success("登出成功");
      localStorage.clear();
      removeToken();
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000)
    } catch (e) {
      Message.error("登出失败!" + e)
    }
  }
  const handleChange = (value: any) => {
    setActiveNodes(value)
    localStorage.setItem('projectInfor', JSON.stringify(value))
  }

  return (<>

    <div className="flex justify-between items-center  pr-[24px] w-full relative ">
      <div className="cascader-wrap">
        <Cascader
          placeholder='请选择算法'
          style={{ width: 240, background: 'white' }}
          options={projectTree}
          value={activeNodes}
          onChange={(value: any) => handleChange(value)}
          showSearch
        ></Cascader>
      </div>
      <div className="right flex-center">
        <Dropdown
          position='br'
          droplist={
            <Menu>
              <Menu.Item key='2' onClick={() => setVisible(true)}     ><span style={{ padding: '0 10px' }}>个人中心</span></Menu.Item>
              <Menu.Item key='1' onClick={loginOut}     ><span style={{ padding: '0 10px' }}>注销</span></Menu.Item>
            </Menu>
          }
        >
          <img src={props?.userInfo?.user?.avatar || DemoImg} className="w-[32px] h-[32px] rounded-full object-contain cursor-pointer" loading="lazy" alt="头像" />
        </Dropdown>
      </div>
    </div>
    <PersonalCenter visible={visible} setVisible={setVisible}></PersonalCenter>
  </>
  )
}

export default Index;