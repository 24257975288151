import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getToken } from '@/utils/apis/auth';
import { tansParams,blobValidate  } from '@/utils/apis/ruoyi';
import { HttpStatus, errorCode } from './RespEnum';
import { encryptBase64, encryptWithAes, generateAesKey, decryptWithAes, decryptBase64, encrypt, decrypt } from '@/utils/apis/crypto';
import { Message } from "@arco-design/web-react"
import FileSaver from 'file-saver';

export const VITE_APP_CLIENT_ID = "dce41dca2ad7cfaa5c3e306472571f0d"
const encryptHeader = 'encrypt-key';

export const globalHeaders = () => {
  return {
    Authorization: 'Bearer ' + getToken(),
    clientid: VITE_APP_CLIENT_ID
  };
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['clientid'] = VITE_APP_CLIENT_ID;
// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '/api',
  // timeout: 100000
  timeout: 300000
});

// 请求拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 申请token时不需要加token
    const isToken = String(config.headers?.isToken) === 'true';
    // 是否需要加密
    const isEncrypt = String(config.headers?.isEncrypt) === 'true';
    if (!isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

    // 当开启参数加密
    if (isEncrypt && (config.method === 'post' || config.method === 'put')) {
      // 生成一个 AES 密钥
      const aesKey = generateAesKey();
      config.headers[encryptHeader] = encrypt(encryptBase64(aesKey));
      config.data = typeof config.data === 'object' ? encryptWithAes(JSON.stringify(config.data), aesKey) : encryptWithAes(config.data, aesKey);
    }
    // FormData数据去请求头Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }
    return config;
  },
  (error: any) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res: AxiosResponse) => {
    // 加密后的 AES 秘钥
    const keyStr = res.headers[encryptHeader];
    // 加密
    if (keyStr) {
      const data = res.data;
      // 请求体 AES 解密
      const base64Str = decrypt(keyStr);
      // base64 解码 得到请求头的 AES 秘钥
      const aesKey = decryptBase64(base64Str.toString());
      // aesKey 解码 data
      const decryptData = decryptWithAes(data, aesKey);
      // 将结果 (得到的是 JSON 字符串) 转为 JSON
      res.data = JSON.parse(decryptData);
    }
    // 未设置状态码则默认成功状态
    const code = res.data.code || HttpStatus.SUCCESS;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default'];
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data;
    }
    if (code === 401) {
      window.location.href = '/login';
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    } else if (code === HttpStatus.SERVER_ERROR) {
      console.log(msg);
      return Promise.reject(new Error(msg));
    } else if (code === HttpStatus.SUCCESS || code === 0) {
      return Promise.resolve(res.data);
    } else {
      Message.error(msg);
      return Promise.reject(msg);
    }
  },
  (error: any) => {
    let { message } = error;
    if (message === 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    Message.error(message)
    return Promise.reject(error);
  }
);


export function download(url: string, params: any, fileName: string, setDownloading: React.Dispatch<React.SetStateAction<boolean>>) {
  return service.post(url, params, {
      transformRequest: [
        (params: any) => {
          return tansParams(params);
        }
      ],
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob'
    }).then(async (resp: any) => {
      const isLogin = blobValidate(resp);
      if (isLogin) {
        const blob = new Blob([resp]);
        FileSaver.saveAs(blob, fileName);
        setDownloading(false);
        Message.success("下载成功")
      } else {
        const resText = await resp.text();
        const rspObj = JSON.parse(resText);
        if (url.indexOf("download_density_report_byweek") > -1) {// 周报告还在生成中
            const runningWeek = rspObj.data
                .filter((item:any) => item.status === 'running')
                .map((item: any) => item.week)
                .join('\n')
            Message.warning({
                content: "以下周期的浓度报告还在生成中，请稍后重试：\n" + runningWeek,
                closable: true,
                duration: 10000,
                style: { whiteSpace: 'pre-line' },
            })
        } else if(url.indexOf("download_density_report_biweekly") || url.indexOf("download_density_report_term")){ //双周报告还在生成中
          if(rspObj?.data?.status === 'running'){
            setTimeout(() => {
              download(url, params, fileName, setDownloading);
            }, 10000); // 10秒后重新调用
          }
        }
        else {
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
            Message.error("下载失败:"+errMsg)
        }
      }
    }).catch((r: any) => {
      console.error(r);
      Message.error('下载文件出现错误，请联系管理员！'+r);
    });
}

export const GET = function (url: string, params: any = {}, headers: any = {}) {
  return service.get(url, {
    params,
    headers
  })
}

export const POST = function (url: string, data: any = {}, headers: any = {}) {
  return service.post(url, data, {
    headers
  })
}
export const DELETE = function (url: string, params: any = {}, headers: any = {}) {
  return service.delete(url,{
    params,
    headers
  })
}
export const PUT = function (url: string, data: any = {}, headers: any = {}) {
  return service.put(url,data, {
    headers
  })
}

// 导出 axios 实例
export default service;