import { useLocation } from "react-router-dom";
import { Breadcrumb } from '@arco-design/web-react';
import router from "@/router/index"
import { useEffect, useState } from "react";
const BreadcrumbItem = Breadcrumb.Item;
interface IRouter {
  name?: string;
  index?: boolean;
  path: string;
  component: any;
  children?: Array<IRouter>
  meta?:{ title: string }
}
const Breadcrumbs = () => {
  const [title,setTitle]=useState('')
  const location = useLocation();
  const generateBread=(path:string,router:IRouter[])=>{
    for(let i=0;i<router.length;i++){
      if(path===router[i].path){
        if(router[i].meta?.title){
          setTitle(router[i].meta!.title)
          return 
        }
      }else if(router[i].children&&router[i].children!.length>0){
        generateBread(path,router[i].children!)
      }

    }
    
  }
useEffect(()=>{generateBread(location.pathname,router)},[location])
  return <div className="w-full pt-1 pb-1 pl-2 cursor-pointer">
          <Breadcrumb style={{ fontSize: 12 }}>
            <BreadcrumbItem>{title}</BreadcrumbItem>
          </Breadcrumb>
        </div>
};

export default Breadcrumbs;