import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import router from './router';
import "@arco-design/web-react/dist/css/arco.css";
import PageLoading from "@/components/PageLoading";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  {/* <Suspense fallback={<PageLoading />}> */}
    <Routes>
      {
        router.map((item) => {
          const Component = item.component;
          return (
          <Route key={item.path} path={item.path} element={ <Component />
          }>
            {
              item.children?.map(i => {
                const ChildComponent = i.component;
                if (i.index) {
                  return (<React.Fragment key={i.path}>
                    <Route index element={<ChildComponent />}></Route>
                    <Route path={i.path} element={<ChildComponent />}></Route>
                  </React.Fragment>)
                } else {
                  return <Route key={i.path} path={i.path} element={<ChildComponent />}></Route>
                }
              })
            }
          </Route>
          )
        })
      }

    </Routes>
    {/* </Suspense> */}
  </BrowserRouter>
);
