import { useEffect, useState,startTransition } from "react";
import { Menu } from "@arco-design/web-react";
import { useNavigate, useLocation } from "react-router-dom";
import SLogo from "@/assets/icons/SLogo.svg";
import BLogo from "@/assets/icons/BLogo.svg";
import Annalyse from "@/assets/icons/Annalyse.svg";
import Home from "@/assets/icons/Home.svg";
import "../index.scss";

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Slider = ({ iscollapsed ,userInfo}: { iscollapsed: boolean ,userInfo:any}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [openKeys, setOpenKeys] = useState<string[]>(["/AIBoard"]);

  useEffect(() => {
    setOpenKeys([location.pathname.split("/")[1]]);
  }, [location.pathname]);

  return (
    <>
      <div
        className={`${
          iscollapsed ? "w-[50px]" : "w-[200px]"
        } h-[52px] flex-center bg-slate-50 border-right border-gray-300 shadow`}
      >
        {iscollapsed ? (
          <SLogo width="30px" height="30px"></SLogo>
        ) : (
          <BLogo width="180px" height="30px" />
        )}
      </div>
      <Menu
        defaultOpenKeys={openKeys}
        selectedKeys={[location.pathname]} 
        levelIndent={40}
        onClickMenuItem={(key) => {
          if (key !== location.pathname) {
            startTransition(()=>{
              navigate(key, { replace: false }); 
            })
          }
        }}
        style={{ width: "100%" }}
      >
        <SubMenu
          key="/AIBoard"
          popup={false}
          title={
            <span>
              <Home
                fill={
                  location.pathname.includes("/AIBoard") ? "#165dff" : "#86909c"
                }
                width="22px"
                height="22px"
                className="inline-block mr-[8px]"
              ></Home>
              AI看板
            </span>
          }
        >
          <MenuItem key="/AIBoard/dashboard">AI数据分析</MenuItem>
          <MenuItem key="/AIBoard/myReport">我的报告</MenuItem>
        </SubMenu>
        {(userInfo?.permissions.includes('*:*:*')||userInfo?.permissions.includes('project:dashboard:bi'))&&
         <SubMenu
         key="/proxyBoard"
         popup={false}
         title={
           <span>
             <Annalyse
               fill={
                 location.pathname.includes("/proxyBoard")
                   ? "#165dff"
                   : "#86909c"
               }
               width="22px"
               height="22px"
               className="inline-block mr-[8px]"
             ></Annalyse>
             代运营数据看板
           </span>
         }
       >
         <MenuItem key="/proxyBoard/dashboard">运营数据分析</MenuItem>
         <MenuItem key="/proxyBoard/breakdownManage">BreakDown管理</MenuItem>
       </SubMenu>
        }
       
      </Menu>
    </>
  );
};

export default Slider;
